import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

type ResturantCardProps = {
    id: string;
    image: string;
    additionalInfo: string;
    header: string;
    subText: string;
}

export default function ResturantCard(
    {
        id,
        image,
        additionalInfo,
        header,
        subText
    }: ResturantCardProps
) {
  const navigate = useNavigate();
  
  const onLocate = () => {
    navigate(`/locator?storeId=${id}`);
  }

  return (
    <Card sx={{ maxWidth: 345 }} elevation={2}>
      <CardMedia
        sx={{ height: 140 }}
        image={image}
        title={additionalInfo}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          { header }
        </Typography>
        <Typography variant="body2" color="text.secondary">
          { subText }
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onLocate}>Locate</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}