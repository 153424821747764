import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ResturantsPage from "./pages/ResturantsPage";

const Home = lazy(() => import('./pages/Home'));
const Locator = lazy(() => import('./pages/Locator'));
const Stores = lazy(() => import('./pages/Stores'));

export const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      children: [
        {
          path: "locator",
          element: <Locator />
        },
        {
          path: "stores",
          element: <ResturantsPage />
        }
      ],
    },
]);