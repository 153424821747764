import { Resturants } from "../models/resturants.type";

export const resturants: Resturants[] = [
    {
        id: 'g4',
        name: 'Store 1',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g5',
        name: 'Store 2',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g6',
        name: 'Store 3',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g7',
        name: 'Store 4',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g8',
        name: 'Store 5',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g9',
        name: 'Store 6',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g10',
        name: 'Store 7',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
        id: 'g47',
        name: 'Store 8',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s',
        image: 'https://upload.wikimedia.org/wikipedia/commons/6/62/Barbieri_-_ViaSophia25668.jpg',
        additionalInfo: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    }
];
